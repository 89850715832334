@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #211d17;
  --secondary: #e7bb57;
  --neutral: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#mobile_menu {
  animation: slideIn ease-in-out 0.5s;
}

@keyframes slideIn {
  from {
    top: -300px;
  }
  to {
    top: 52px;
  }
}

#landing_page {
  animation: fadeInBg 3s ease-in;
  background-image: linear-gradient(
      to top,
      rgba(33, 29, 23, 9),
      rgba(231, 187, 87, 0.2)
    ),
    url("./imgs/home.webp");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

@keyframes fadeInBg {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

#headLine_one {
  animation: fadeInOne 6s ease-in;
}

@keyframes fadeInOne {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#headLine_two {
  animation: fadeInTwo 4s ease-in;
}

@keyframes fadeInTwo {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.btn {
  animation: fadeInBtn 6s ease-in;
}

@keyframes fadeInBtn {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fa-angle-down {
  animation: MoveUpDown 3s ease-in-out infinite;
  position: relative;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 40px;
  }
}

/* Loader Styling */
#loader_wrapper {
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--neutral);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: top;
}

.loader {
  margin-top: 12rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-top: 3px solid var(--secondary);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-moz-selection {
  color: var(--secondary);
  background: var(--secondary);
}

::selection {
  color: var(--secondary);
  background: var(--primary);
}
